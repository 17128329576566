const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const year = day * 365;

function getAboutYear(): string {
    return Math.ceil(
        (Date.now() - new Date('2020-10-22').getTime()) / year
    ).toString();
}

export { getAboutYear };
