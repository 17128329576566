import { getAboutYear } from '../utils';

export const MASTER_DATA = {
    name: 'Duong Nam Truong',
    headline: 'Technical Leader',
    email: 'hi@duongnamtruong.com',
    about: `I am a Software Engineer with ${
        3 + +getAboutYear()
    } years of experience in Web, Mobile, Desktop, and Blockchain application development.`,
    resume_link: '#',
    awards: {
        '1': {
            title: 'Prospective individual 2022 - Fabbi JSC',
            description: `With my passion and enthusiasm in 2022, I dedicated myself to the company on numerous major projects, successfully solving complex problems and contributing to the company's profitability.`,
            image: '/images/ca-nhan-trien-vong-fabbi-2022.png',
            alt: 'Me, December 2022',
        },
    },
    twitter: 'truongdn__it',
    experiences: {
        '1': {
            company: 'Fabbi JSC',
            description:
                'Currently, I work as a Technical Leader at Fabbi JSC, where my primary focus is on Web3 and Blockchain development. Additionally, I often inspire and motivate others within the company.',
            url: 'https://fabbi.com.vn',
            headline: 'Technical Leader',
            startDate: '10/2020',
            endDate: 'Present',
        },
    },

    github: 'truongdn-it',
    linkedin: 'truongdn-it',
    os: 'TruongDNOS',
};
