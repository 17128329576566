import React from 'react';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';
import { MASTER_DATA } from '../../constants/masterData';
import AboutList from '../features/about/AboutList';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm {MASTER_DATA.name}</h3>
            <br />
            <div className="text-block">
                <p>{MASTER_DATA.about}</p>
                <br />
                <p>
                    Thank you for taking the time to check out my portfolio. I
                    really hope you enjoy exploring it as much as I enjoyed
                    building it. If you have any questions or comments, feel
                    free to contact me using{' '}
                    <Link to="/contact">this form</Link> or shoot me an email at{' '}
                    <a href={`mailto:${MASTER_DATA.email}`}>
                        {MASTER_DATA.email}
                    </a>
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>Awards</h3>

                {Object.keys(MASTER_DATA.awards).map((award) => {
                    return (
                        <AboutList
                            title={
                                MASTER_DATA.awards[
                                    award as keyof typeof MASTER_DATA.awards
                                ].title
                            }
                            key={award}
                            description={
                                MASTER_DATA.awards[
                                    award as keyof typeof MASTER_DATA.awards
                                ].description
                            }
                            alt={
                                MASTER_DATA.awards[
                                    award as keyof typeof MASTER_DATA.awards
                                ].alt
                            }
                            image={
                                MASTER_DATA.awards[
                                    award as keyof typeof MASTER_DATA.awards
                                ].image
                            }
                        />
                    );
                })}

                <br />
                <br />
                <p>
                    Thanks for reading about me! I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer. If you find the easter egg make sure to let me know
                    on twitter{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`https://twitter.com/${MASTER_DATA.twitter}`}
                    >
                        @{MASTER_DATA.twitter}
                    </a>{' '}
                    Good luck and have fun!
                </p>
                <br />
                <p>
                    If you have any questions or comments I would love to hear
                    them. You can reach me through the{' '}
                    <Link to="/contact">contact page</Link> or shoot me an email
                    at{' '}
                    <a href={`mailto:${MASTER_DATA.email}`}>
                        {MASTER_DATA.email}
                    </a>
                </p>
            </div>
        </div>
    );
};

export default About;
