import React from 'react';
import ResumeDownload from './ResumeDownload';
import { MASTER_DATA } from '../../constants/masterData';
import ExperienceList from '../features/experience/ExperienceList';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = () => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            {Object.keys(MASTER_DATA.experiences).map((exp) => {
                return (
                    <ExperienceList
                        key={exp}
                        company={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].company
                        }
                        description={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].description
                        }
                        startDate={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].startDate
                        }
                        endDate={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].endDate
                        }
                        headline={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].headline
                        }
                        url={
                            MASTER_DATA.experiences[
                                exp as keyof typeof MASTER_DATA.experiences
                            ].url
                        }
                    />
                );
            })}
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
