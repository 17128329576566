import React from 'react';

function AboutList({
    description,
    image,
    alt,
    title,
}: {
    description: string;
    image: string;
    alt: string;
    title: string;
}) {
    return (
        <>
            <br />
            <div style={{}}>
                <div
                    style={{
                        flex: 1,
                        textAlign: 'justify',
                        alignSelf: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <h3>{title}</h3>
                    <br />
                    <p>{description}</p>
                    <br />
                </div>
                <div style={styles.verticalImage}>
                    <img
                        draggable={false}
                        src={image}
                        style={styles.image}
                        alt=""
                    />
                    <p>
                        <sub>
                            <b>Figure 1:</b> {alt}
                        </sub>
                    </p>
                </div>
            </div>
        </>
    );
}

export default AboutList;

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};
